import { lazy } from 'react';
import { styled } from 'styled-components';
import { Play } from '@hn-ui/icons';
import { useToggle } from '@hn-front/utils';

const Modal = lazy(() =>
  import('@hn-ui/modals').then((module) => ({
    default: module.Modal,
  })),
);

interface ICardProps {
  previewUrl: string;
  cardUrl: string;
  text: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const PlayCard = styled(Play)`
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:
    width 0.2s,
    height 0.2s;
  animation-name: fade;
  animation-duration: 1s;
`;

const Img = styled.img`
  width: 250px;
  height: 140px;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-out;
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  &:hover {
    & > ${PlayCard} {
      width: 34px;
      height: 34px;
    }
    & > ${Overlay} {
      opacity: 0.25;
    }
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05px;
`;

const FrameContainer = styled.div`
  max-height: 477px;
  max-width: 728px;
  height: 477px;
  width: 728px;
`;

const CardFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;

  width: 1024px;
  height: 630px;
  transform: scale(0.71);
  transform-origin: 0 0;
`;

const ScModal = styled(Modal)`
  [data-id='modal-box'] {
    width: auto;
  }
`;

const Card = ({ previewUrl, cardUrl, text, ...rest }: ICardProps) => {
  const { isToggled, toggle } = useToggle();

  return (
    <Container {...rest} onClick={toggle}>
      <ImageContainer>
        <Img src={previewUrl} alt="card-preview" loading="lazy" />
        <Overlay />
        <PlayCard size={20} />
      </ImageContainer>
      <Text>{text}</Text>
      {isToggled && (
        <ScModal open={true} onClose={toggle} showOverlay size="xl">
          <FrameContainer>
            <CardFrame
              title="preview iframe"
              allowFullScreen={true}
              src={cardUrl}
              loading="lazy"
            />
          </FrameContainer>
        </ScModal>
      )}
    </Container>
  );
};

export { Card };
