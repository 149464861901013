import { styled } from 'styled-components';
import { Text, Title1 } from './Common';
import { buildAwsStaticUrl } from 'app/utils';
import { Star } from '@hn-ui/icons';
import { Form, Link } from '@remix-run/react';
import { teacherAuthUrls } from 'app/utils/urls.data';

const Container = styled.section`
  display: flex;
  flex-flow: column;
  gap: 24px;
`;

const Title = styled(Title1)`
  ${({ theme: { down } }) => down('tabletSm')} {
    text-align: left;
  }
`;

const ScText = styled(Text)`
  max-width: 720px;
  align-self: center;
  ${({ theme: { down } }) => down('tabletSm')} {
    text-align: left;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  & > a {
    min-width: 350px;
  }

  ${({ theme: { down } }) => down('tabletMd')} {
    & > a {
      min-width: 300px;
    }
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    flex-flow: column;
    gap: 24px;
    & > a {
      width: 100%;
    }
  }
`;

const TeacherLoginButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  font-weight: 700;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  height: fit-content;
  font-family: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  color: ${({ theme }) => theme.colors.black100};
  border-radius: 12px;
  min-width: 320px;
  background: ${({ theme }) => theme.colors.blue60};
  padding: 12px 22px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${({ theme }) => theme.colors.blue50};
    }
  }
  ${({ theme: { down } }) => down('tabletMd')} {
    width: 294px;
    padding: 8px;
  }
`;

const StudentLoginButton = styled(TeacherLoginButton)`
  && {
    background: #ffc500;

    &:hover {
      background: #cc9d00;
    }
  }
`;

const Texts = styled.div`
  display: flex;
  flex-flow: column;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 284px;

  ${({ theme: { down } }) => down('tabletMd')} {
    height: 186px;
  }
`;

const Image = styled.img`
  position: absolute;
  display: block;
  bottom: 0;
  object-fit: cover;
  left: 50%;
  transform: translateX(-50%);
  object-position: left;
  z-index: -1;
  max-width: unset;

  ${({ theme: { down } }) => down('tabletMd')} {
    height: inherit;
  }

  ${({ theme: { down } }) => down('phone')} {
    display: none;
  }
`;

const ImageMobile = styled(Image)`
  display: none;
  width: calc(100% + 48px);
  ${({ theme: { down } }) => down('phone')} {
    display: block;
  }
`;

const Background = styled.img`
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 100vw;
  max-height: 100%;
  object-fit: cover;
  object-position: center bottom;
  z-index: -1;
  transform: translateX(-50%);

  ${({ theme: { down } }) => down('phone')} {
    display: none;
  }
`;

const BackgroundMobile = styled(Background)`
  display: none;
  ${({ theme: { down } }) => down('phone')} {
    display: block;
  }
`;

const ButtonText1 = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ButtonText2 = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 11px;
    line-height: 26px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    display: none;
  }
`;

const LogoContainer = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 70px;
    justify-content: center;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    flex-wrap: wrap;
    gap: 24px;
    max-width: 400px;
    align-self: center;
  }
`;

const LogoBlockItem = styled.li`
  display: flex;
  gap: 16px;

  ${({ theme: { down } }) => down('tabletMd')} {
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    width: calc(50% - 40px);
  }
`;

const Img = styled.img`
  width: 67px;
  height: 82px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 8px;
`;

const LogoText = styled.p`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
`;

const Stars = styled.div`
  display: flex;
  gap: 10px;
`;

const CleverStar = () => <Star color="#4274F6" size={16} />;

const TitleSection = () => {
  return (
    <Container>
      <Title>Math Centers that Deliver – Differentiation Done Right</Title>
      <ScText>
        While you teach small groups, Happy Numbers individualizes instruction
        for the rest of the class, and gives you actionable insights about
        student growth.
      </ScText>
      <Form action="/">
        <Controls>
          <TeacherLoginButton as={Link} to={teacherAuthUrls.login}>
            <Texts>
              <ButtonText1>I’m a Teacher</ButtonText1>
              <ButtonText2>
                Sign in to your account or sign up for one
              </ButtonText2>
            </Texts>
          </TeacherLoginButton>
          <StudentLoginButton href="/find_class">
            <Texts>
              <ButtonText1>I’m a Student</ButtonText1>
              <ButtonText2>
                Sign in to your account (created by your teacher)
              </ButtonText2>
            </Texts>
          </StudentLoginButton>
        </Controls>
      </Form>
      <ImageContainer>
        <Background
          src={buildAwsStaticUrl('/teachers/main-page/home-image-bg.svg')}
          alt="home background"
        />
        <BackgroundMobile
          src={buildAwsStaticUrl(
            '/teachers/main-page/home-image-bg-mobile.svg',
          )}
          alt="home background mobile"
        />
        <Image
          src={buildAwsStaticUrl('/teachers/main-page/home-image.svg')}
          alt="home"
          fetchPriority="high"
        />
        <ImageMobile
          src={buildAwsStaticUrl('/teachers/main-page/home-image-mobile.svg')}
          alt="home mobile"
        />
      </ImageContainer>
      <LogoContainer>
        <LogoBlockItem>
          <Img
            src={buildAwsStaticUrl('/teachers/main-page/essa-logo.png')}
            alt="essa-logo"
            loading="lazy"
          />
          <DescriptionContainer>
            <LogoText>
              Evidence
              <br />
              for ESSA
            </LogoText>
          </DescriptionContainer>
        </LogoBlockItem>
        <LogoBlockItem>
          <Img
            src={buildAwsStaticUrl('/teachers/main-page/classlink-logo.svg')}
            alt="classlink-logo"
            loading="lazy"
          />
          <DescriptionContainer>
            <LogoText>
              ClassLink Curriculum
              <br />& Content Partner
            </LogoText>
          </DescriptionContainer>
        </LogoBlockItem>
        <LogoBlockItem>
          <Img
            src={buildAwsStaticUrl('/teachers/main-page/ferpa-logo.svg')}
            alt="ferpa-logo"
            loading="lazy"
          />
          <DescriptionContainer>
            <LogoText>
              COPPA, FERPA,
              <br />
              AB1584 Compliant
            </LogoText>
          </DescriptionContainer>
        </LogoBlockItem>
        <LogoBlockItem>
          <Img
            src={buildAwsStaticUrl('/teachers/main-page/clever-logo.svg')}
            alt="clever-logo"
            loading="lazy"
          />
          <DescriptionContainer>
            <Stars>
              <CleverStar />
              <CleverStar />
              <CleverStar />
              <CleverStar />
              <CleverStar />
            </Stars>
            <LogoText>
              Clever Top-Rated
              <br />
              Math Resource
            </LogoText>
          </DescriptionContainer>
        </LogoBlockItem>
      </LogoContainer>
    </Container>
  );
};

export { TitleSection };
