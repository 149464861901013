import { styled } from 'styled-components';
import { Title2 } from './Common';
import { TestimonialSlider } from './testimonials/TestimonialSlider';
import { BlueButton } from '@hn-ui/buttons';
import { teacherAuthUrls } from 'app/utils/urls.data';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 32px;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 24px;
  }
`;

const LoginButton = styled(BlueButton)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
  }
  ${({ theme: { down } }) => down('phone')} {
    width: 100%;
  }
`;

const Title = styled(Title2)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ReviewSection = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <Title>
        Happy Numbers is helping 2 million students grow in math!
        <br />
        Here's what their teachers think:
      </Title>
      <TestimonialSlider />
      <LoginButton forwardedAs="a" href={teacherAuthUrls.signup} fit>
        Get it started for free
      </LoginButton>
    </Container>
  );
};

export { ReviewSection };
