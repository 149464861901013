import { redirectDocument } from '@remix-run/node';
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { metaData } from './meta';
import { styled } from 'styled-components';
import { TitleSection } from '@components/main-page/TitleSection';
import { ReviewSection } from '@components/main-page/ReviewSection';
import { ResearchSection } from '@components/main-page/ResearchSection';
import { Differentiation } from '@components/main-page/Differentiation';
import { Setup } from '@components/main-page/Setup';
import { SafeUse } from '@components/main-page/SafeUse';
import { CardsSection } from '@components/main-page/CardsSection';
import { getUser } from 'app/utils/session.server';
import { GO_URL } from 'app/utils/urls.data';

export const meta: MetaFunction = ({ matches }) => {
  const parentMeta = matches
    .flatMap((match) => match.meta)
    .filter((meta) => !('title' in meta));
  return [...parentMeta, ...metaData];
};

const Container = styled.main`
  display: flex;
  flex-flow: column;
  gap: 80px;
  max-width: 992px;
  align-self: center;
  margin: auto;
  padding-top: 56px;
`;

const teacherBase = import.meta.env.VITE_TEACHERS_BASE;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await getUser(request);
  const { searchParams, pathname } = new URL(request.url);
  const redirectNo = searchParams.get('redirect');
  const shouldRedirect =
    (redirectNo === null || redirectNo !== 'no') && pathname === teacherBase;

  if (shouldRedirect) {
    const redirectPath = user?.defaultAccountPath ?? GO_URL;
    throw redirectDocument(redirectPath);
  }

  return null;
};

export default function MainPage() {
  return (
    <Container>
      <TitleSection />
      <ReviewSection />
      <ResearchSection />
      <CardsSection />
      <Differentiation />
      <Setup />
      <SafeUse />
    </Container>
  );
}
