import { styled } from 'styled-components';
import { Text, Title2 } from './Common';
import { buildAwsStaticUrl } from 'app/utils';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const List = styled.ul`
  display: grid;
  gap: 88px;
  margin: 0;
  padding: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  flex-basis: 0;
  gap: 16px;
  align-items: center;
`;

const Img = styled.img`
  width: 128px;
  height: 120px;

  ${({ theme: { down } }) => down('tabletMd')} {
    width: 80px;
    height: 80px;
  }
`;

const SafeUse = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <HeaderContainer>
        <Title2>Safe and Easy to Use</Title2>
        <Text>We took care of everything</Text>
      </HeaderContainer>
      <List>
        <ListItem>
          <Img
            alt="devices"
            src={buildAwsStaticUrl('/teachers/main-page/devices.svg')}
          />
          <Text>Compatible with Any Device & Browser</Text>
        </ListItem>
        <ListItem>
          <Img
            alt="beling"
            src={buildAwsStaticUrl('/teachers/main-page/beling.svg')}
          />
          <Text>Available in Spanish and English</Text>
        </ListItem>
        <ListItem>
          <Img
            alt="support"
            src={buildAwsStaticUrl('/teachers/main-page/support.svg')}
          />
          <Text>Ready to Support Any Time You Need</Text>
        </ListItem>
        <ListItem>
          <Img
            alt="data"
            src={buildAwsStaticUrl('/teachers/main-page/data.svg')}
          />
          <Text>Keep Your Data Protected</Text>
        </ListItem>
      </List>
    </Container>
  );
};

export { SafeUse };
