interface ICardData {
  indicatorCaption: string;
  groupeSrc: [string, string, string][];
}

export const cardsData: ICardData[] = [
  {
    indicatorCaption: 'PK',
    groupeSrc: [
      [
        'https://static.happynumbers.com/main-page/count-in-rows.png',
        'https://happynumbers.com/demo/slider_card/1?iframe=true',
        'Count in Rows',
      ],
      [
        'https://static.happynumbers.com/main-page/buttons-on-jacket.png',
        'https://happynumbers.com/demo/slider_card/2?iframe=true',
        'Count in Columns',
      ],
      [
        'https://static.happynumbers.com/main-page/rectangle-above-triangle.png',
        'https://happynumbers.com/demo/slider_card/3?iframe=true',
        'Choose Above or Below',
      ],
    ],
  },
  {
    indicatorCaption: 'K',
    groupeSrc: [
      [
        'https://static.happynumbers.com/main-page/more-fingers.png',
        'https://happynumbers.com/demo/slider_card/4?iframe=true',
        'Count and Choose More',
      ],
      [
        'https://static.happynumbers.com/main-page/cubes-pattern.png',
        'https://happynumbers.com/demo/slider_card/5?iframe=true',
        'Build an Addition Equation',
      ],
      [
        'https://static.happynumbers.com/main-page/count-birds.png',
        'https://happynumbers.com/demo/slider_card/6?iframe=true',
        'Count Moving Objects',
      ],
    ],
  },
  {
    indicatorCaption: 'Gr. 1',
    groupeSrc: [
      [
        'https://static.happynumbers.com/main-page/biggest-block.png',
        'https://happynumbers.com/demo/slider_card/7?iframe=true',
        'Solve +/- with Base-10 Blocks',
      ],
      [
        'https://static.happynumbers.com/main-page/help-to-jump.png',
        'https://happynumbers.com/demo/slider_card/8?iframe=true',
        'Subtract on a Number Line',
      ],
      [
        'https://static.happynumbers.com/main-page/how-many.png',
        'https://happynumbers.com/demo/slider_card/9?iframe=true',
        'Count Tens and Ones',
      ],
    ],
  },
  {
    indicatorCaption: 'Gr. 2',
    groupeSrc: [
      [
        'https://static.happynumbers.com/main-page/add-using-disk-model.png',
        'https://happynumbers.com/demo/slider_card/10?iframe=true',
        '2-Digit Addition with a Model',
      ],
      [
        'https://static.happynumbers.com/main-page/compare-numbers.png',
        'https://happynumbers.com/demo/slider_card/11?iframe=true',
        'Compare Using <, +, >',
      ],
      [
        'https://static.happynumbers.com/main-page/column-addition.png',
        'https://happynumbers.com/demo/slider_card/12?iframe=true',
        '3-Digit Column Addition',
      ],
    ],
  },
  {
    indicatorCaption: 'Gr. 3',
    groupeSrc: [
      [
        'https://static.happynumbers.com/main-page/how-many-rows.png',
        'https://happynumbers.com/demo/slider_card/13?iframe=true',
        'Multiply Using Arrays',
      ],
      [
        'https://static.happynumbers.com/main-page/tile-each-shape.png',
        'https://happynumbers.com/demo/slider_card/14?iframe=true',
        'Compare Shapes by Tiling',
      ],
      [
        'https://static.happynumbers.com/main-page/inches-long.png',
        'https://happynumbers.com/demo/slider_card/15?iframe=true',
        'Label Fractions of a Whole',
      ],
    ],
  },
  {
    indicatorCaption: 'Gr. 4',
    groupeSrc: [
      [
        'https://static.happynumbers.com/main-page/multiply-ones.png',
        'https://happynumbers.com/demo/slider_card/16?iframe=true',
        'Multiply with/without a Model',
      ],
      [
        'https://static.happynumbers.com/main-page/devide-using-place-value-chart.png',
        'https://happynumbers.com/demo/slider_card/17?iframe=true',
        'Divide with/without a Model',
      ],
      [
        'https://static.happynumbers.com/main-page/whole-fraction.png',
        'https://happynumbers.com/demo/slider_card/18?iframe=true',
        'Record Fractions and Decimals',
      ],
    ],
  },
  {
    indicatorCaption: 'Gr. 5',
    groupeSrc: [
      [
        'https://static.happynumbers.com/main-page/how-many-thousands.png',
        'https://happynumbers.com/demo/slider_card/19?iframe=true',
        'Column Addition with Decimals',
      ],
      [
        'https://static.happynumbers.com/main-page/complete-expression.png',
        'https://happynumbers.com/demo/slider_card/20?iframe=true',
        'Use Exponents with 10',
      ],
      [
        'https://static.happynumbers.com/main-page/wisard.png',
        'https://happynumbers.com/demo/slider_card/21?iframe=true',
        'Factor out 10s to Multiply',
      ],
    ],
  },
];
