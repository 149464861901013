import { styled } from 'styled-components';
import { BlueIconButton } from '@hn-ui/buttons';
import { Back, Forward } from '@hn-ui/icons';
import { Card } from './Card';
import { cardsData } from './cardsData';
import { useCallback, useState } from 'react';
import { CaptionButton } from './CaptionButton';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 32px;
`;

const SliderContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: 24px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PickerContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ArrowButton = styled(BlueIconButton)`
  transform: translateY(-18px);
`;

const captions = cardsData.map((card) => card.indicatorCaption);
const totalCards = cardsData.length;

const CardSlider = ({ ...rest }) => {
  const [position, setPosition] = useState<number>(0);

  const onLeftButtonClick = useCallback(() => {
    setPosition((prevPosition) =>
      prevPosition === 0 ? totalCards - 1 : prevPosition - 1,
    );
  }, []);

  const onRightButtonClick = useCallback(() => {
    setPosition((prevPosition) =>
      prevPosition === totalCards - 1 ? 0 : prevPosition + 1,
    );
  }, []);

  return (
    <Container {...rest}>
      <SliderContainer>
        <ArrowButton onClick={onLeftButtonClick}>
          <Back size={24} />
        </ArrowButton>
        <CardsContainer>
          {cardsData[position].groupeSrc.map(
            ([previewUrl, cardUrl, caption]) => (
              <Card
                key={previewUrl}
                previewUrl={previewUrl}
                cardUrl={cardUrl}
                text={caption}
              />
            ),
          )}
        </CardsContainer>
        <ArrowButton onClick={onRightButtonClick}>
          <Forward size={24} />
        </ArrowButton>
      </SliderContainer>
      <ControlsContainer>
        <PickerContainer>
          {captions.map((caption, index) => (
            <CaptionButton
              text={caption}
              key={caption}
              isActive={position === index}
              position={index}
              onClick={setPosition}
            />
          ))}
        </PickerContainer>
      </ControlsContainer>
    </Container>
  );
};

export { CardSlider };
