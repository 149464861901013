import { styled } from 'styled-components';
import { BlueIconButton } from '@hn-ui/buttons';
import { Back, Forward, Circle } from '@hn-ui/icons';
import { Testimonial } from './Testimonial';
import {
  cardsData,
  cardsDataMobile,
  type ITestimonialData,
} from './testimonialsData';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowSize } from '@hn-front/utils';
import { useSwipeable } from 'react-swipeable';
import type { ShadedColors } from '@hn-ui/themes';

const mobileCardWidth = 288;
const mobileGapWidth = 0;

const getSliderShift = (
  position: number,
  cardWidth: number,
  gapWidth: number,
) => -position * (cardWidth + gapWidth);

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 32px;

  ${({ theme: { down } }) => down('phone')} {
    width: 100%;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 24px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    gap: 16px;
  }
`;

const CardsOuterContainer = styled.div`
  max-width: 858px;
  overflow: hidden;

  ${({ theme: { down } }) => down('tabletMd')} {
    max-width: 646px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    max-width: 536px;
  }

  ${({ theme: { down } }) => down('phone')} {
    max-width: 100%;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  gap: 24px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 16px;
  }

  ${({ theme: { down } }) => down('phone')} {
    grid-template-columns: repeat(15, 100%);
    gap: 0;
  }
`;

const ScTestimonial = styled(Testimonial)`
  && {
    ${({ theme: { down } }) => down('tabletMd')} {
      width: 310px;
    }
    ${({ theme: { down } }) => down('tabletSm')} {
      width: 260px;
    }
    ${({ theme: { down } }) => down('phone')} {
      width: 100%;
    }
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

type CardsOptions = {
  visibleCards: number;
  cardWidth: number;
  gapWidth: number;
};

const getCardsOptionsByWidth = (width: number): CardsOptions => {
  if (width == 0) return { visibleCards: 0, cardWidth: 0, gapWidth: 0 };

  if (width >= 1024) {
    return { visibleCards: 3, cardWidth: 270, gapWidth: 24 };
  } else if (width >= 768) {
    return { visibleCards: 2, cardWidth: 310, gapWidth: 16 };
  } else if (width >= 640) {
    return { visibleCards: 2, cardWidth: 260, gapWidth: 16 };
  }

  return {
    visibleCards: 1,
    cardWidth: mobileCardWidth,
    gapWidth: mobileGapWidth,
  };
};

const getCardsTotalNumber = (
  visibleCards: number,
  cards: ITestimonialData[],
) => {
  switch (visibleCards) {
    case 3:
      return cards?.length;
    case 2:
      return 14;
    case 1:
      return 8;
    default:
      return 8;
  }
};

const PickerContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ScCircle = styled(Circle)`
  && > rect {
    fill: ${({ theme, color }) => theme.colors[color as keyof ShadedColors]};
  }
`;

const Button = styled(BlueIconButton)`
  ${({ theme: { down } }) => down('phone')} {
    && {
      display: none;
    }
  }
`;

const TestimonialSlider = ({ ...rest }) => {
  const [position, setPosition] = useState(0);
  const [arrowPress, setArrowPress] = useState(true);
  const sliderRef = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  const [{ visibleCards, cardWidth, gapWidth }, setVisibleCards] =
    useState<CardsOptions>(getCardsOptionsByWidth(width));

  useEffect(() => {
    if (pickerRef.current && pickerRef.current.getBoundingClientRect().y < 60) {
      sliderRef.current?.scrollIntoView();
      window.scrollBy(0, -60);
    }
  }, [arrowPress]);

  useEffect(() => {
    setVisibleCards(getCardsOptionsByWidth(width));
  }, [width]);

  const cards = useMemo(() => {
    if (visibleCards === 0) return [];
    if (visibleCards === 1)
      return cardsDataMobile.slice(
        0,
        getCardsTotalNumber(visibleCards, cardsDataMobile),
      );
    return cardsData.slice(0, getCardsTotalNumber(visibleCards, cardsData));
  }, [visibleCards]);

  const maxPosition = useMemo(
    () => cards.length - visibleCards,
    [cards.length, visibleCards],
  );

  const onLeftButtonClick = useCallback(() => {
    setArrowPress(!arrowPress);
    setPosition((prev) => {
      const newPosition = prev - visibleCards;

      if (newPosition < 0) {
        return maxPosition;
      }

      return newPosition;
    });
  }, [maxPosition, visibleCards, arrowPress]);

  const onRightButtonClick = useCallback(() => {
    setArrowPress(!arrowPress);
    setPosition((prev) => {
      const newPosition = prev + visibleCards;

      if (newPosition > maxPosition) {
        return 0;
      }

      return newPosition;
    });
  }, [maxPosition, visibleCards, arrowPress]);

  const onPickerClick = useCallback(
    (pickerPosition: number) => {
      setArrowPress(!arrowPress);
      setPosition(pickerPosition);
    },
    [arrowPress],
  );

  const sliderShift = getSliderShift(position, cardWidth, gapWidth);

  const { ref: swipeableRef } = useSwipeable({
    onSwipedLeft: onLeftButtonClick,
    onSwipedRight: onRightButtonClick,
    trackMouse: true,
  });

  if (!visibleCards) return null;

  return (
    <Container {...rest}>
      <SliderContainer>
        <Button onClick={onLeftButtonClick}>
          <Back size={32} />
        </Button>
        <CardsOuterContainer ref={swipeableRef}>
          <CardsContainer
            ref={swipeableRef}
            style={{
              transform:
                visibleCards === 1
                  ? `translateX(${-position * 100}%)`
                  : `translateX(${sliderShift}px)`,
            }}
          >
            {cards.map((card) => (
              <ScTestimonial key={card.id} {...card} />
            ))}
          </CardsContainer>
        </CardsOuterContainer>
        <Button onClick={onRightButtonClick}>
          <Forward size={32} />
        </Button>
      </SliderContainer>
      <ControlsContainer>
        <PickerContainer>
          {new Array(Math.ceil(cards.length / visibleCards))
            .fill(null)
            .map((_, index) => {
              const isCurrent = position / visibleCards === index;
              const pickerPosition = visibleCards * index;

              return (
                <BlueIconButton
                  key={index}
                  onClick={() => onPickerClick(pickerPosition)}
                  disabled={isCurrent}
                  tabIndex={-1}
                  aria-label="slider-pagination-button"
                >
                  <ScCircle size={10} color={isCurrent ? 'blue60' : 'blue90'} />
                </BlueIconButton>
              );
            })}
        </PickerContainer>
      </ControlsContainer>
    </Container>
  );
};

export { TestimonialSlider };
