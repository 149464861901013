import { styled } from 'styled-components';
import { Title2, Title3 } from './Common';
import { buildAwsStaticUrl } from 'app/utils';
import { Link } from '@components/links/Link';
import { ButtonLink } from '@components/links/ButtonLink';
import { useToggle } from '@hn-front/utils';
import { TextButton } from '@hn-ui/buttons';
import { BlueLink } from '@hn-ui/links';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
  align-self: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 488px;
  gap: 40px;
  align-items: center;

  ${({ theme: { down } }) => down('tabletMd')} {
    grid-template-columns: 1fr 355px;
    align-items: flex-start;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`;

const Img = styled.img`
  width: 488px;
  height: 276px;

  ${({ theme: { down } }) => down('tabletMd')} {
    height: auto;
  }
  ${({ theme: { down } }) => down('tabletSm')} {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 16px;
    line-height: 24px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const ScLink = styled(Link)`
  font-size: 18px;
  line-height: 30px;
  display: inline;
  white-space: normal;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 16px;
    line-height: 24px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const backgroundUrl = buildAwsStaticUrl('/teachers/main-page/research-bg.svg');

const ResearchBasedContainer = styled(InnerContainer)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.blue95};
  border-radius: 16px;
  background-image: url(${backgroundUrl});
  background-repeat: no-repeat;
  background-position: right calc(100% + 16px);
  background-size: contain;
  padding: 26px 32px 26px 48px;

  ${({ theme: { down } }) => down('tabletSm')} {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px 36px;
  }
`;

const ResearchTitle = styled(Title2)`
  width: fit-content;
  font-size: 24px;
  line-height: 32px;

  ${({ theme: { down } }) => down('tabletSm')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ResearchBasedInner = styled.div`
  display: flex;
  flex-flow: column;
  gap: 24px;

  ${({ theme: { down } }) => down('tabletSm')} {
    gap: 16px;
    align-items: center;
  }
`;

const EssaContainer = styled(ResearchBasedInner)`
  flex-flow: row;
  align-items: center;
`;

const EssaImg = styled.img`
  width: 94px;
  height: 114px;

  ${({ theme: { down } }) => down('tabletMd')} {
    width: 66px;
    height: 80px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    width: 60px;
    height: 74px;
  }
`;

const EssaText = styled.p`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ScContentContainer = styled(ContentContainer)`
  align-items: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;

  ${({ theme: { down } }) => down('tabletSm')} {
    grid-row: 1;
  }
`;

const MoreButton = styled(TextButton)`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  padding: 0;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 16px;
    line-height: 24px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const DynoImg = styled.img`
  width: 104px;
  height: 139px;
`;

const DynoText = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;

  ${({ theme: { down } }) => down('tabletSm')} {
    font-size: 12px;
  }
`;

const QuoteImg = styled.img`
  width: 68px;
  height: 58px;
  transform: translateY(-24px);

  && {
    ${({ theme: { down } }) => down('tabletSm')} {
      width: 30px;
      height: 25px;
    }
  }
`;

const GeorgeQuoteContainer = styled(InnerContainer)`
  background: ${({ theme }) => theme.colors.blue99};
  width: 100%;
  padding: 60px 32px 32px 96px;
  gap: 16px;
  position: relative;
  margin-top: 96px;

  ${({ theme: { down } }) => down('tabletSm')} {
    padding: 60px 8px 32px;
  }
`;

const QuoteTextContainer = styled.div`
  display: flex;
  gap: 16px;

  ${({ theme: { down } }) => down('tabletSm')} {
    flex-flow: column;
    gap: 0;
  }
`;

const QuoteText = styled.p`
  color: ${({ theme }) => theme.colors.black60};
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 24px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    line-height: 22px;
  }
`;

const QuoteLink = styled(BlueLink)`
  font-size: 18px;
  line-height: 30px;
  white-space: normal;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 24px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    line-height: 22px;
  }
`;

const QuoteContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const GeorgeContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  align-self: flex-end;

  ${({ theme: { down } }) => down('tabletSm')} {
    align-self: flex-start;
  }
`;

const GeorgeImg = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const GeorgeTextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
`;

const GeorgeTitle = styled.p`
  color: ${({ theme }) => theme.colors.black60};
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;

  ${({ theme: { down } }) => down('tabletSm')} {
    font-size: 12px;
  }
`;

const GeorgeText = styled.p`
  color: ${({ theme }) => theme.colors.black60};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
`;

const DynoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: -116px;
  left: 16px;
`;

const ColledgeImg = styled(Img)`
  ${({ theme: { down } }) => down('tabletSm')} {
    width: 100%;
  }
`;

const ViewAllButton = styled(ButtonLink)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const Title = styled(Title2)`
  text-align: start;
`;

const ResearchSection = ({ ...rest }) => {
  const { isToggled: isMeaningToggled, toggle: toggleMeaning } = useToggle();
  const { isToggled: isScaffoldingToggled, toggle: toggleScaffolding } =
    useToggle();

  return (
    <Container {...rest}>
      <InnerContainer>
        <Title>College Readiness Lives or Dies in Elementary Classrooms</Title>
        <ContentContainer>
          <Text>
            Only ⅓ of US high school students are ready for college math.{' '}
            <ScLink to="https://my.nctm.org/blogs/evgeny-milyutin/2018/09/10/algebra-or-primary-math-crisis-1">
              Those who lag behind their peers by grade 8 have been on this
              clear trajectory since kindergarten
            </ScLink>
            . To prepare today’s elementary students for the jobs of tomorrow,
            we first need to prepare them for high school and college.
            <br />
            <br />
            This is why implementing quality resources and instruction in grades
            PK-5 is so critical.
          </Text>
          <ColledgeImg
            src={buildAwsStaticUrl('/teachers/main-page/college-readiness.svg')}
            alt="college-readiness"
            loading="lazy"
          />
        </ContentContainer>
      </InnerContainer>
      <ResearchBasedContainer>
        <ResearchBasedInner>
          <ResearchTitle>Happy Numbers is research based</ResearchTitle>
          <ViewAllButton
            to="/blog/happy-numbers-results-proven-by-research/"
            target="_blank"
            fit
          >
            View all studies
          </ViewAllButton>
        </ResearchBasedInner>
        <EssaContainer>
          <EssaImg
            src={buildAwsStaticUrl('/teachers/main-page/essa-logo.png')}
            alt="essa-logo"
            loading="lazy"
          />
          <EssaText>
            Evidence
            <br />
            for ESSA
          </EssaText>
        </EssaContainer>
      </ResearchBasedContainer>
      <InnerContainer>
        <Title>Depth over Breadth, Quality over Quantity</Title>
        <Text>
          “All-singing, all-dancing” resources don’t lead to deep conceptual
          understanding. Time-tested pedagogy and research do. Happy Numbers is
          built on this vision － empowered by the latest technology and shaped
          through our work with real teachers across the country.
        </Text>
        <ContentContainer>
          <Img
            src={buildAwsStaticUrl('/teachers/main-page/match-pattern.svg')}
            alt="match-pattern"
            loading="lazy"
          />
          <TextContainer>
            <Title3>Connected PK – 5 Math Story</Title3>
            <Text>
              Math by its very nature is an interconnected subject that builds
              on itself, and so is Happy Numbers. It’s a coherent PK-5 math
              story, not a shopping list of stand-alone rules and processes. The
              thoughtfully-designed progression of skills, concepts,
              connections, and tools that students acquire through Happy Numbers
              ensures a firm foundation for middle school and beyond.
            </Text>
          </TextContainer>
        </ContentContainer>
        <ScContentContainer>
          <TextContainer>
            <Title3>Meaning behind Math with Every Touch</Title3>
            <Text>
              Mastering math is about more than just getting the right answer.
              Students need to understand the reasoning behind their actions,
              having the flexibility to respond to challenges along the way.
              <br />
              <br />
              {isMeaningToggled
                ? `Happy Numbers technology allows for hands-on modeling with a
              diverse range of manipulatives. Through multiple representations (number line, hundred chart, base-10 blocks, etc.), students approach math from different angles and move from concrete to abstract thinking. New concepts are introduced strategically – students acquire new learning while reinforcing prior knowledge and forging connections between the two.

With Happy Numbers, students learn to "think math" and achieve procedural fluency. `
                : 'Happy Numbers technology allows for hands-on modeling with a diverse range of manipulatives. Through multiple representations '}
              <MoreButton onClick={toggleMeaning} fit>
                {isMeaningToggled ? 'Read less' : 'Read more...'}{' '}
              </MoreButton>
            </Text>
          </TextContainer>
          <Img
            src={buildAwsStaticUrl('/teachers/main-page/math-meaning.svg')}
            alt="math-meaning"
            loading="lazy"
          />
        </ScContentContainer>
        <ScContentContainer>
          <Img
            src={buildAwsStaticUrl(
              '/teachers/main-page/individualized-scaffolding.svg',
            )}
            alt="individualized-scaffolding"
            loading="lazy"
          />
          <TextContainer>
            <Title3>Individualized Scaffolding and Feedback</Title3>
            <Text>
              Happy Numbers builds an individualized math dialogue and responds
              to students just the way you would: unpacking concepts
              step-by-step, scaffolding learning, and providing immediate
              feedback based on student inputs.
              <br />
              <br />
              {isScaffoldingToggled
                ? `Individualized pacing means those who demonstrate mastery progress more quickly, and those who need more instruction receive it. As a result, students spend most of their time in productive struggle mode (also known as the Zone of Proximal Development), which leads to the greatest learning gains. And to ensure students never get stuck, targeted feedback helps them correct any mistake – students experience mistakes as opportunities for learning! `
                : 'Individualized pacing means those who demonstrate mastery progress more quickly, and those who need more instruction receive it. As a result, '}
              <MoreButton onClick={toggleScaffolding} fit>
                {isScaffoldingToggled ? 'Read less' : 'Read more...'}
              </MoreButton>
            </Text>
          </TextContainer>
        </ScContentContainer>
      </InnerContainer>
      <GeorgeQuoteContainer>
        <DynoContainer>
          <DynoImg
            src={buildAwsStaticUrl('/teachers/main-page/dino-glad.svg')}
            alt="dino-glad"
            loading="lazy"
          />
          <DynoText>
            And did we mention?
            <br />
            ...students love it!
          </DynoText>
        </DynoContainer>
        <QuoteContainer>
          <QuoteTextContainer>
            <QuoteImg
              src={buildAwsStaticUrl('/teachers/main-page/quotes.svg')}
              alt="quotes"
              loading="lazy"
            />
            <QuoteText>
              [Happy Numbers] is the most comprehensive and effective technology
              teaching tool
              <br />
              <QuoteLink href="https://happynumbers.com/blog/hitting-math-benchmarks-in-k-2-case-study/">
                I have ever used for my Kindergarten and First Grade classes!
              </QuoteLink>
            </QuoteText>
          </QuoteTextContainer>
        </QuoteContainer>
        <GeorgeContainer>
          <GeorgeImg
            src={buildAwsStaticUrl('/teachers/main-page/george.png')}
            alt="george"
            loading="lazy"
          />
          <GeorgeTextContainer>
            <GeorgeTitle>George Regan</GeorgeTitle>
            <GeorgeText>
              Slackwood Elementary School
              <br />
              in New Jersey
            </GeorgeText>
          </GeorgeTextContainer>
        </GeorgeContainer>
      </GeorgeQuoteContainer>
    </Container>
  );
};

export { ResearchSection };
