import { styled } from 'styled-components';
import { Title2 } from './Common';
import { BlueButton } from '@hn-ui/buttons';
import { teacherAuthUrls } from 'app/utils/urls.data';
import { useNonce } from 'app/utils/nonce-provider';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;
`;

const VideoContainer = styled.div`
  width: 856px;
  height: 480px;

  ${({ theme: { down } }) => down('tabletMd')} {
    width: 636px;
    height: 360px;
  }
  ${({ theme: { down } }) => down('tabletSm')} {
    width: 100%;
    height: 305px;
  }
`;

const Video = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Button = styled(BlueButton)`
  ${({ theme: { down } }) => down('tabletSm')} {
    width: 100%;
  }
`;

const Setup = ({ ...rest }) => {
  const nonce = useNonce();
  return (
    <Container {...rest}>
      <Title2>Super Easy to Set Up</Title2>
      <VideoContainer>
        <Video
          id="ytplayer"
          title="easy as 123"
          allowFullScreen
          height="360"
          width="640"
          src="https://www.youtube.com/embed/bgXOn-A0KZY?color=white"
          srcDoc={`<style nonce=${nonce}>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;height: inherit;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/bgXOn-A0KZY?color=white&autoplay=1&mute=1><img src=https://s3.us-west-1.amazonaws.com/happy-s3-static2/teachers/main-page/maxresdefault.jpg alt=embed-video width=640 height=360' decoding='async' data-nimg='responsive'><span>▶</span></a>`}
          loading="lazy"
        />
      </VideoContainer>
      <Button forwardedAs="a" href={teacherAuthUrls.signup} fit>
        Set up your class!
      </Button>
    </Container>
  );
};

export { Setup };
