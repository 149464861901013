import { styled } from 'styled-components';
import { Text, Title2 } from './Common';
import { CardSlider } from './cards/CardSlider';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 32px;

  ${({ theme: { down } }) => down('tabletSm')} {
    display: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const CardsSection = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <TitleContainer>
        <Title2>Quick Sneak Peak</Title2>
        <Text>Experience it yourself as you view a student</Text>
      </TitleContainer>
      <CardSlider />
    </Container>
  );
};

export { CardsSection };
