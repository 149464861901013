import { styled } from 'styled-components';

export interface ICaptionButtonProps {
  text: string;
  position: number;
  onClick: (position: number) => void;
  isActive?: boolean;
}

const Line = styled.div`
  width: 50px;
  height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.black90};

  transition:
    border-color 0.5s ease-out,
    background-color 0.5s ease-out;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.black90};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  transition: color 0.5s ease-out;
`;

const Container = styled.button`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 8px;
  background: transparent;
  cursor: pointer;

  &:hover {
    ${Line} {
      background: ${({ theme }) => theme.colors.blue50};
    }
    ${Text} {
      color: ${({ theme }) => theme.colors.blue50};
    }
  }
  &[aria-pressed='true'] {
    ${Line} {
      background: ${({ theme }) => theme.colors.blue60};
    }
    ${Text} {
      color: ${({ theme }) => theme.colors.blue60};
    }
  }
`;

const CaptionButton = ({
  text,
  position,
  onClick,
  isActive = false,
  ...rest
}: ICaptionButtonProps) => (
  <Container
    aria-pressed={isActive}
    onClick={() => onClick(position)}
    {...rest}
  >
    <Line />
    <Text>{text}</Text>
  </Container>
);

export { CaptionButton };
