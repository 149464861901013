import { styled } from 'styled-components';
import { Text, Title2, Title3 } from './Common';
import { buildAwsStaticUrl } from 'app/utils';
import { BlueLink } from '@hn-ui/links';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 40px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 40px;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 24px;
  }

  ${({ theme: { down } }) => down('tabletSm')} {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const ScText = styled(Text)`
  text-align: start;
`;

const Img = styled.img`
  width: 488px;
  height: 416px;
  align-self: center;
`;

const List = styled.ul`
  margin: 0;
  padding-left: 24px;
`;

const ListItem = styled.li``;

const InstructionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-end;

  ${({ theme: { down } }) => down('phone')} {
    display: none;
  }
`;

const Circle = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.blue95};
`;

const InstructionTextContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.blue95};
  border-bottom-left-radius: 58px;
  border-top-left-radius: 58px;
  padding: 16px 32px 12px 32px;
`;

const InstructionText = styled(Text)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const ScImg = styled(Img)`
  ${({ theme: { down } }) => down('tabletMd')} {
    width: 355px;
    height: 100%;
  }
  ${({ theme: { down } }) => down('phone')} {
    width: 100%;
  }
`;

const Link = styled(BlueLink)`
  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const Differentiation = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <TitleContainer>
        <Title2>Differentiation = Success for Every Student</Title2>
        <ScText>
          The average 1-to-1-instructed student scores higher than 98% of those
          in a conventional non-differentiated classroom. That’s the power of
          individualized instruction: every student can succeed.
        </ScText>
      </TitleContainer>
      <ContentContainer>
        <TextContainer>
          <Title3>How can a teacher be everywhere at once?</Title3>
          <ScText>
            While we can’t clone teachers, Happy Numbers can ensure students get
            quality instruction when working independently (freeing up teachers
            to differentiate with small groups):
          </ScText>
          <List>
            <ListItem>
              <ScText>
                Each student starts off at the right point in the curriculum
                thanks to built-in Quantile-based assessments
              </ScText>
            </ListItem>
            <ListItem>
              <ScText>
                Students progress at their own pace through our pedagogy-rich
                math story, supported by instant, individualized feedback and
                scaffolding
              </ScText>
            </ListItem>
            <ListItem>
              <ScText>
                Teachers get actionable insights from Happy Numbers to inform
                their differentiation strategy
              </ScText>
            </ListItem>
          </List>
        </TextContainer>
        <ScImg
          src={buildAwsStaticUrl('/teachers/main-page/differentiation.svg')}
          alt="differentiation"
          loading="lazy"
        />
      </ContentContainer>
      <InstructionContainer>
        <Circle />
        <Circle />
        <InstructionTextContainer>
          <InstructionText>
            Why individualized instruction?{' '}
            <Link href="https://shared.happynumbers.com/whitepaper.pdf">
              Download the Whitepaper
            </Link>
            .
          </InstructionText>
        </InstructionTextContainer>
      </InstructionContainer>
    </Container>
  );
};

export { Differentiation };
