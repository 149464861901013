import { styled } from 'styled-components';
import { Star } from '@hn-ui/icons';
import { useClampText } from 'use-clamp-text';
import { TextButton } from '@hn-ui/buttons';
import { useToggle } from '@hn-front/utils';

interface ITestimonialProps {
  author: string;
  publishDate: string;
  teacherGradeInfo: string;
  text: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  background: ${({ theme }) => theme.colors.black100};
  border: 1px solid ${({ theme }) => theme.colors.black90};
  border-radius: 10px;
  padding: 16px;
  gap: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const AuthorContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Author = styled.p`
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const Date = styled.p`
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black50};
`;

const TeacherName = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.colors.black50};
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 22px;
  }
`;

const StarsContainer = styled.div`
  display: flex;
  gap: 2px;
`;

const StarIcon = styled(Star)`
  width: 14px;
  height: 14px;

  ${({ theme: { down } }) => down('tabletMd')} {
    width: 10px;
    height: 10px;
  }
`;

const ShowMore = styled(TextButton)`
  && {
    padding: 0;
    font-weight: 400;
  }
`;

const Testimonial = ({
  author,
  publishDate,
  teacherGradeInfo,
  text,
  ...rest
}: ITestimonialProps) => {
  const [ref, { noClamp, clampedText }] = useClampText({
    text,
    ellipsis: '...',
    lines: 7,
  });
  const { isToggled, toggle } = useToggle();

  return (
    <Container {...rest}>
      <HeaderContainer>
        <AuthorContainer>
          <Author>{author}</Author>
          <Date>{publishDate}</Date>
        </AuthorContainer>
        <TeacherName>{`${teacherGradeInfo} | Verified user`}</TeacherName>
        <StarsContainer>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </StarsContainer>
      </HeaderContainer>
      <TextContainer>
        <Text ref={ref as React.MutableRefObject<HTMLParagraphElement>}>
          {isToggled ? text : clampedText}
        </Text>
        {!noClamp && (
          <ShowMore fit onClick={toggle}>
            {isToggled ? 'Show less' : 'Show more'}
          </ShowMore>
        )}
      </TextContainer>
    </Container>
  );
};

export { Testimonial };
