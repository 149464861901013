export interface ITestimonialData {
  id: string;
  author: string;
  publishDate: string;
  teacherGradeInfo: string;
  text: string;
}

export const cardsData: ITestimonialData[] = [
  {
    id: '1',
    author: 'MOLLY B.',
    publishDate: 'Dec 2023',
    teacherGradeInfo: 'Teaches 3rd Grade',
    text: 'MY CLASS LOVES HAPPY NUMBERS!! We use it for leveled practice, whether remediation/review or extension and extended practice!',
  },
  {
    id: '2',
    author: 'SAMUETTA D.',
    publishDate: 'Dec 2023',
    teacherGradeInfo: 'Teaches Kindergarten',
    text: "I've been using Happy Numbers going on my 2nd year. My students love this program. I love that I can see in real time what my students are struggling with and address the area that they need support in immediately.",
  },
  {
    id: '3',
    author: 'KRISTEN M.',
    publishDate: 'Nov 2023',
    teacherGradeInfo: 'Teaches Many Grades',
    text: 'I have used this program at two different schools over 3 years for kindergarten students. I absolutely love it, and so do the kids. I have spoken with teachers at all grade levels (K-5) who feel the same way, so I feel confident in my recommendation for elementary in general. It has so much depth from the "back side." I can see where they have been, where they are and where I need them to go. I can see when they are stuck or need help. I can assign modules in the order that I am teaching. I can pull a report at a whim, and get definitive assessment results with their mid-year and end of year assessments. I have students regularly logging in at home because they are so motivated! We give out certificates when they pass a module.',
  },
  {
    id: '4',
    author: 'LINDSEY B.',
    publishDate: 'Oct 2023',
    teacherGradeInfo: 'Teaches 5th Grade',
    text: 'As an intervention specialist, I have found Happy Numbers to be educational and motivating for the 5th graders I work with. My students do not seem to mind doing the "work" to get to the "games." The way that the program structures the tutorials aligns with the models that our state test would expect kids to interpret and produce. We love Happy Numbers!',
  },
  {
    id: '5',
    author: 'MELISSA M.',
    publishDate: 'Sep 2023',
    teacherGradeInfo: 'Teaches 5th Grade',
    text: 'I LOVE Happy Numbers! Last year my fifth grade students started using this program in February. In as little as 3 months, I saw such huge growth! They loved trying to get their task and time target in order to unlock the games. I feel it is a really easy, user friendly program that keeps the children constantly learning.',
  },
  {
    id: '6',
    author: 'CYNTHIA S.',
    publishDate: 'Sep 2023',
    teacherGradeInfo: 'Teaches Many Grades',
    text: 'What I like best about Happy Numbers is the engaging and quick tutorials that address a concept that a child may be struggling with as they progress through the app. I loved using this in my combination 1/2. My high second graders were challenged and new concepts were addressed in a clear manner and my struggling First Graders practiced and reviewed skills that they had missed. Two thumbs up!!!',
  },
  {
    id: '7',
    author: 'KATRINA P.',
    publishDate: 'Aug 2023',
    teacherGradeInfo: 'Teaches 1st Grade',
    text: 'My kids love this! I also love this program!! It goes at their pace, gives me helpful data, goes along with my math curriculum as well as NWEA testing.',
  },
  {
    id: '8',
    author: 'JENNY G.',
    publishDate: 'Apr 2023',
    teacherGradeInfo: 'Teaches 1st Grade',
    text: "I use the app as an activity after students are finished with the lesson's practice. It is differentiated and I can help individuals with skills based on the reports I receive with their progress. My students love the idea of turning the two targets green for the week. I also challenge them to a classroom task goal each week.",
  },
  {
    id: '9',
    author: 'ZACHARY O.',
    publishDate: 'Nov 2022',
    teacherGradeInfo: 'Teaches Many Grades',
    text: "Perfect center activity where my special education students could learn independently and receive digital support in-app. Reporting made it easy to see where my students were shining and struggling in specific areas of Eureka's curriculum. It was easy to assign students activities and lessons that mirrored what we were doing in-class or if they needed a brush up on a certain skill.",
  },
  {
    id: '10',
    author: 'KATHARYN B.',
    publishDate: 'Dec 2022',
    teacherGradeInfo: 'Teaches Kindergarten',
    text: 'Such a great resource for all levels to individualize practice and generate data for individuals and full class so instruction can be strengthened according to weaknesses.',
  },
  {
    id: '11',
    author: 'ALEX L.',
    publishDate: 'Jan 2024',
    teacherGradeInfo: 'Teaches 2nd Grade',
    text: "My favorite learning app is Happy Numbers. It provides math skills at the students level once they take the placement test. It's very easy to keep track of students' progress through the curriculum since their starting point. I love I can print activities and reports to share with parents. I can also change the language to Spanish if students are struggling with the new math concepts. My students love it! They enjoy the art, interactions and games they play.",
  },
  {
    id: '12',
    author: 'DAWN H.',
    publishDate: 'Sep 2023',
    teacherGradeInfo: 'Teaches 3rd Grade',
    text: 'Students love this! I love that it starts with concrete representations meeting students at his/her working level and moves them to abstract.',
  },
  {
    id: '13',
    author: 'TAVIA G.',
    publishDate: 'Jun 2023',
    teacherGradeInfo: 'Teaches 2nd Grade',
    text: "Happy Numbers is an engaging skilled based resource that reinforces math concepts taught in the classroom. It's a great resource!",
  },
  {
    id: '14',
    author: 'AMY A.',
    publishDate: 'Jan 2024',
    teacherGradeInfo: 'Teaches Many Grades',
    text: 'Love giving this as a supplemental independent work center as I rotate groups in my classroom.',
  },
  {
    id: '15',
    author: 'MICHELLE M.',
    publishDate: 'Nov 2023',
    teacherGradeInfo: 'Teaches 4th Grade',
    text: 'Use in a supplement and intervention focused each day. My students like working through Happy Numbers and enjoy learning and reviewing skills we have taught and even ones we have not taught yet.',
  },
];

export const cardsDataMobile: ITestimonialData[] = [
  {
    id: '1',
    author: 'MOLLY B.',
    publishDate: 'Dec 2023',
    teacherGradeInfo: 'Teaches 3rd Grade',
    text: 'MY CLASS LOVES HAPPY NUMBERS!! We use it for leveled practice, whether remediation/review or extension and extended practice!',
  },
  {
    id: '2',
    author: 'SAMUETTA D.',
    publishDate: 'Dec 2023',
    teacherGradeInfo: 'Teaches Kindergarten',
    text: "I've been using Happy Numbers going on my 2nd year. My students love this program. I love that I can see in real time what my students are struggling with and address the area that they need support in immediately.",
  },
  {
    id: '3',
    author: 'KRISTEN M.',
    publishDate: 'Nov 2023',
    teacherGradeInfo: 'Teaches Many Grades',
    text: 'I have used this program at two different schools over 3 years for kindergarten students. I absolutely love it, and so do the kids. I have spoken with teachers at all grade levels (K-5) who feel the same way, so I feel confident in my recommendation for elementary in general. It has so much depth from the "back side." I can see where they have been, where they are and where I need them to go. I can see when they are stuck or need help. I can assign modules in the order that I am teaching. I can pull a report at a whim, and get definitive assessment results with their mid-year and end of year assessments. I have students regularly logging in at home because they are so motivated! We give out certificates when they pass a module.',
  },
  {
    id: '4',
    author: 'LINDSEY B.',
    publishDate: 'Oct 2023',
    teacherGradeInfo: 'Teaches 5th Grade',
    text: 'As an intervention specialist, I have found Happy Numbers to be educational and motivating for the 5th graders I work with. My students do not seem to mind doing the "work" to get to the "games." The way that the program structures the tutorials aligns with the models that our state test would expect kids to interpret and produce. We love Happy Numbers!',
  },
  {
    id: '5',
    author: 'MELISSA M.',
    publishDate: 'Sep 2023',
    teacherGradeInfo: 'Teaches 5th Grade',
    text: 'I LOVE Happy Numbers! Last year my fifth grade students started using this program in February. In as little as 3 months, I saw such huge growth! They loved trying to get their task and time target in order to unlock the games. I feel it is a really easy, user friendly program that keeps the children constantly learning.',
  },
  {
    id: '6',
    author: 'CYNTHIA S.',
    publishDate: 'Sep 2023',
    teacherGradeInfo: 'Teaches Many Grades',
    text: 'What I like best about Happy Numbers is the engaging and quick tutorials that address a concept that a child may be struggling with as they progress through the app. I loved using this in my combination 1/2. My high second graders were challenged and new concepts were addressed in a clear manner and my struggling First Graders practiced and reviewed skills that they had missed. Two thumbs up!!!',
  },
  {
    id: '7',
    author: 'KATRINA P.',
    publishDate: 'Aug 2023',
    teacherGradeInfo: 'Teaches 1st Grade',
    text: 'My kids love this! I also love this program!! It goes at their pace, gives me helpful data, goes along with my math curriculum as well as NWEA testing.',
  },
  {
    id: '8',
    author: 'JENNY G.',
    publishDate: 'Apr 2023',
    teacherGradeInfo: 'Teaches 1st Grade',
    text: "I use the app as an activity after students are finished with the lesson's practice. It is differentiated and I can help individuals with skills based on the reports I receive with their progress. My students love the idea of turning the two targets green for the week. I also challenge them to a classroom task goal each week.",
  },
  {
    id: '9',
    author: 'ZACHARY O.',
    publishDate: 'Nov 2022',
    teacherGradeInfo: 'Teaches Many Grades',
    text: "Perfect center activity where my special education students could learn independently and receive digital support in-app. Reporting made it easy to see where my students were shining and struggling in specific areas of Eureka's curriculum. It was easy to assign students activities and lessons that mirrored what we were doing in-class or if they needed a brush up on a certain skill.",
  },
  {
    id: '10',
    author: 'KATHARYN B.',
    publishDate: 'Dec 2022',
    teacherGradeInfo: 'Teaches Kindergarten',
    text: 'Such a great resource for all levels to individualize practice and generate data for individuals and full class so instruction can be strengthened according to weaknesses.',
  },
  {
    id: '11',
    author: 'ALEX L.',
    publishDate: 'Jan 2024',
    teacherGradeInfo: 'Teaches 2nd Grade',
    text: "My favorite learning app is Happy Numbers. It provides math skills at the students level once they take the placement test. It's very easy to keep track of students' progress through the curriculum since their starting point. I love I can print activities and reports to share with parents. I can also change the language to Spanish if students are struggling with the new math concepts. My students love it! They enjoy the art, interactions and games they play.",
  },
  {
    id: '12',
    author: 'DAWN H.',
    publishDate: 'Sep 2023',
    teacherGradeInfo: 'Teaches 3rd Grade',
    text: 'Students love this! I love that it starts with concrete representations meeting students at his/her working level and moves them to abstract.',
  },
  {
    id: '13',
    author: 'TAVIA G.',
    publishDate: 'Jun 2023',
    teacherGradeInfo: 'Teaches 2nd Grade',
    text: "Happy Numbers is an engaging skilled based resource that reinforces math concepts taught in the classroom. It's a great resource!",
  },
  {
    id: '14',
    author: 'AMY A.',
    publishDate: 'Jan 2024',
    teacherGradeInfo: 'Teaches Many Grades',
    text: 'Love giving this as a supplemental independent work center as I rotate groups in my classroom.',
  },
  {
    id: '15',
    author: 'MICHELLE M.',
    publishDate: 'Nov 2023',
    teacherGradeInfo: 'Teaches 4th Grade',
    text: 'Use in a supplement and intervention focused each day. My students like working through Happy Numbers and enjoy learning and reviewing skills we have taught and even ones we have not taught yet.',
  },
];
