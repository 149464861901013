export const metaData = [
  {
    title:
      'Math Centers that Deliver — Differentiation Done Right | HappyNumbers.com',
  },
  {
    name: 'description',
    content:
      'Math Centers that Deliver — Differentiation Done Right | HappyNumbers.com',
  },
  {
    property: 'og:title',
    content:
      'Math Centers that Deliver — Differentiation Done Right | HappyNumbers.com',
  },
  {
    property: 'og:description',
    content:
      'While you teach small groups, Happy Numbers serves as an independent math center, providing individualized instruction for the rest of the class.',
  },
  {
    name: 'twitter:title',
    content:
      'Math Centers that Deliver — Differentiation Done Right | HappyNumbers.com',
  },
  {
    name: 'twitter:description',
    content:
      'While you teach small groups, Happy Numbers serves as an independent math center, providing individualized instruction for the rest of the class.',
  },
  {
    name: 'twitter:image:alt',
    content:
      'Math Centers that Deliver — Differentiation Done Right | HappyNumbers.com',
  },
];
