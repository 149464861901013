import { css, styled } from 'styled-components';

const titleCss = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.6px;
  text-align: center;

  && {
    ${({ theme: { down } }) => down('tabletMd')} {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const Title1 = styled.h1`
  ${titleCss}
`;

export const Title2 = styled.h2`
  ${titleCss}
`;

export const Title3 = styled.h3`
  ${titleCss}
  font-size: 24px;
  text-align: left;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  white-space: pre-line;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 14px;
    line-height: 24px;
  }
`;
